
import * as Yup from 'yup';
import {computed, defineComponent, onMounted, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {useField, useForm} from 'vee-validate';
import {VatTuService} from '@/core/services/VatTu.service';
import {setCurrentPageTitle} from '@/core/helpers/breadcrumb';
import { getErrorMsg, isResponseError, swalErrNotification, swalNotification} from '@/core/helpers/utils';
import {useLoading} from '@/core/hooks/use-loading';
import {useRouterCustom} from '@/core/hooks/use-router-custom';
import {IVatTuTrongKho} from '@/core/interfaces/Models';
import { AccountantItemResponse } from '../../../../core/interfaces/ApiResponses';
import { useButtonCustom } from '../../../../core/hooks/use-button-custom';
import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';
import DanhMucVatTuService from '@/core/services/DanhMucVatTu.service';
import CurrencyInputCustom from '@/components/forms-elements/CurrencyInputCustom.vue';
import TaiKhoanSelection from '../../../../components/shared/tai-khoan-selection/TaiKhoanSelection.vue';
import DivRow from '../../../../layout/_shared/DivRow.vue';
import DivCol from '../../../../layout/_shared/DivCol.vue';

export default defineComponent({
  name: "form-supply",

  components: {
		ButtonCustom,
		CurrencyInputCustom,
		TaiKhoanSelection,
		DivRow,
		DivCol,
  },

  setup() {
    const route = useRoute();
		const { push, replace } = useRouterCustom()
		const { isLoading, startLoading, endLoading, setLoading } = useLoading();
		const { ButtonTypeColors, ButtonsType } = useButtonCustom();

    const router = useRouter();
    const isEditingPage = ref<boolean>(false);
    const firstInput = ref<null | HTMLFormElement>(null);
    const isSupplyCodeDuplicate = ref(false);

    const recall = ref(false);
    const isApprovedByComp = ref(false);
		const idDanhMucVatTu = ref(0);
		const tenDanhMucVatTu = ref('');
		const khoItems = ref<IVatTuTrongKho[]>([]);
		const thongTinTaiKhoan = ref({
			ma_chi_tiet: '',
			ma_tai_khoan: 0,
			ten_tk: '',
		})

    const supplyValidator = Yup.object({
      name: Yup.string().required("Tên vật tư là bắt buộc"),
      minimumReserve: Yup.number()
				.typeError('Phải là số')
				.required("Số lượng dự trữ tối thiểu vật tư phải nhập")
        .min(0, "Số lượng dự trữ tối thiểu băt buộc phải lớn hơn hoặc bằng 0"),
      maximumReserve: Yup.number()
				.typeError('Phải là số')
        .required("Số lượng dự trữ tối đa vật tư phải nhập")
        .min(0, "Số lượng dự trữ tối đa băt buộc phải lớn hơn hoặc bằng 0"),
    });

    const { validate } = useForm({
      validationSchema: supplyValidator,
    });

    const { value: name, errorMessage: nameErr } = useField<string>("name");

    const { value: code, errorMessage: codeErr } = useField<string>("code");

    const { value: unitString, errorMessage: unitStringErr } =
      useField<string>("unitString");

    const { value: minimumReserve, errorMessage: minimumReserveErr } =
      useField<number>("minimumReserve");

    const { value: maximumReserve, errorMessage: maximumReserveErr } =
      useField<number>("maximumReserve");

		const { value: soDuDauNam, errorMessage: soDuDauNamErr } =
      useField<number>("soDuDauNam");

		const isInaccessible = computed(() => {
			const routeName = route.name;

			if (routeName === 'update-supply') return process.env.VUE_APP_IS_EDIT_VAT_TU_ENABLE === 'false';

			return process.env.VUE_APP_IS_ADD_VAT_TU_ENABLE === 'false';
		});

    onMounted(async () => {
			if (isInaccessible.value) {
				await swalErrNotification(null, `Bạn không thể ${isEditingPage.value ? 'chỉnh sửa vật tư này' : 'tạo mới vật tư'}`);

				replace('/');
			}

      setCurrentPageTitle("Vật tư");

      if (firstInput.value) {
        firstInput.value.focus();
      }

      if (route.name === "update-supply") {
        isEditingPage.value = true;

        try {
          const {
            data: {
              data: {
                ten_vat_tu,
                ma_vat_tu,
                muc_du_tru_toi_thieu,
                muc_du_tru_toi_da,
                cong_ty_duyet,
                don_vi_tinh,
                thu_hoi,
								danh_muc_vat_tu,
								ma_tk,
								ma_ct,
								so_du_dau_nam,
              },
            },
          } = await VatTuService.fetchSupplyDetail(+route.params.id);

          name.value = ten_vat_tu;

          code.value = ma_vat_tu;

          unitString.value = don_vi_tinh;

          minimumReserve.value = +muc_du_tru_toi_thieu;

          maximumReserve.value = +muc_du_tru_toi_da;

					soDuDauNam.value = so_du_dau_nam;

          recall.value = thu_hoi;

          isApprovedByComp.value = cong_ty_duyet;

					thongTinTaiKhoan.value.ma_chi_tiet = ma_ct || '';

					if (ma_tk) {
						thongTinTaiKhoan.value.ma_tai_khoan = +ma_tk || 0;
					}

					if (danh_muc_vat_tu && danh_muc_vat_tu.length > 0) {
						tenDanhMucVatTu.value = danh_muc_vat_tu[0].ten_danh_muc;

						idDanhMucVatTu.value = danh_muc_vat_tu[0].id;
					}
        } catch (error) {
          await swalNotification(
						'Có lỗi xảy ra khi lấy thông tin vật tư',
						'error',
					);
        }
      }
    });

		const onSubmit = async () => {
			const { valid } = await validate();

			if (valid) {
				startLoading();

				setLoading(true);

				try {
					let supplyId;

					if (isEditingPage.value) {
						const {
							data: {
								data: { id },
							},
						} = await VatTuService.updateSupply(
							+route.params.id,
							name.value,
							unitString.value,
							minimumReserve.value,
							maximumReserve.value,
							recall.value,
							isApprovedByComp.value,
							0,
							idDanhMucVatTu.value,
							code.value,
							thongTinTaiKhoan.value,
							soDuDauNam.value,
						);

						supplyId = id;
					} else {
						const {
							data: { data: res },
						} = await VatTuService.create(
							name.value,
							unitString.value,
							minimumReserve.value,
							maximumReserve.value,
							recall.value,
							isApprovedByComp.value,
							0,
							idDanhMucVatTu.value,
							thongTinTaiKhoan.value,
							soDuDauNam.value,
						);

						if (isResponseError(res)) {
							isSupplyCodeDuplicate.value = true;
							return;
						}

						supplyId = res.id;
					}

					endLoading();
					setLoading(false);
					await router.push({
						path: `/quan-ly/vat-tu/chi-tiet/${supplyId}`,
					});
				} catch (error) {
					endLoading();
					setLoading(false);
					await swalNotification(
						getErrorMsg(error, `Có lỗi xảy ra khi ${isEditingPage.value ? 'chỉnh sửa' : 'tạo mới'} vật tư`),
						'error',
					);
				}
			} else {
				await swalNotification(
					'Thông tin nhập vào chưa chính xác',
					'error',
				)
			}
		}

    return {
      supplyValidator,
      isEditingPage,
			ButtonTypeColors, ButtonsType,
			onSubmit,
      name,
      nameErr,
      code,
      codeErr,
      unitString,
      unitStringErr,
      minimumReserve,
      minimumReserveErr,
      maximumReserve,
      maximumReserveErr,
			soDuDauNam, soDuDauNamErr,
      recall,
      isApprovedByComp,
			tenDanhMucVatTu,
			idDanhMucVatTu,
      firstInput,
      isSupplyCodeDuplicate,
			push,
			isLoading,
			khoItems,
			validate,
			thongTinTaiKhoan,
    };
  },

	methods: {
		async queryDanhMucVatTu(queryString: string, fn) {
			const { data: { data: { data } } } = await DanhMucVatTuService.searchByMaDanhMuc(queryString);

			fn(data);
		},

		onSelectDanhMucVatTu(item) {
			this.idDanhMucVatTu = item.id;
		},

		async onSubmitButtonClicked() {
			await this.onSubmit();
		},

		onSelectAccount({ taiKhoanItem: { ma_tai_khoan, ma_chi_tiet, ten_tai_khoan } }: { taiKhoanItem: AccountantItemResponse }) {
			this.thongTinTaiKhoan.ma_tai_khoan = +ma_tai_khoan;
			if (ma_chi_tiet) {
				this.thongTinTaiKhoan.ma_chi_tiet = ma_chi_tiet
			}

			if (ten_tai_khoan) {
				this.thongTinTaiKhoan.ten_tk = ten_tai_khoan;
			}
		}
	}
});
